import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },

  // Admin Modules
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.DASHBOARD.TEXT",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "monitor",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  },
  {
    path: "/admin/classes/list",
    title: "MENUITEMS.CLASSES.TEXT",
    moduleName: "classes",
    iconType: "feather",
    icon: "square",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  },

  {
    path: "/admin/administrations/list",
    title: "MENUITEMS.ADMINISTRATIONS.TEXT",
    moduleName: "administrations",
    iconType: "feather",
    icon: "briefcase",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  },

  {
    path: "/admin/enseignements/list",
    title: "MENUITEMS.ENSEIGNEMENTS.TEXT",
    moduleName: "enseignements",
    iconType: "feather",
    icon: "book",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  },
  {
    path: "/admin/eleves/list",
    title: "MENUITEMS.ELEVES.TEXT",
    moduleName: "eleves",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  },
  {
    path: "/admin/finances/list",
    title: "MENUITEMS.FINANCES.TEXT",
    moduleName: "finances",
    iconType: "feather",
    icon: "activity",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  },
  {
    path: "/admin/users/list",
    title: "MENUITEMS.USERS.TEXT",
    moduleName: "utilisateurs",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Administrateur"],
    submenu: [],
  }
];
