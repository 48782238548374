import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/login`, {
        email,
        password,
      })
      .pipe(
        map((authResponse) => {
            if(authResponse.authTest){
                const userData = {
                    img: "assets/images/user/admin.jpg",
                    username: authResponse.userData.user.email,
                    firstName: authResponse.userData.personne.prenom,
                    lastName: authResponse.userData.personne.nom,
                    role: authResponse.userData.user.personne_type,
                    token: authResponse.userData.access_token,
                };
                const user = new User(userData);
                localStorage.setItem("currentUser", JSON.stringify(user));
                this.currentUserSubject.next(user);
            }

            return authResponse;

        })
      );
  }

  checkCreateCompte(data){
     return this.http.post(environment.apiUrl+"/auth/check-create-compte", data);
  }

  checkUsernameExists(username: string,username_edit: string=null): Observable<boolean> {
      // Appel au backend pour vérifier si la matricule existe
      let data = {username:username,username_edit:username_edit};
      return this.http
          .post<{ exists: boolean }>(environment.apiUrl+"/auth/check-exist-username",data)
          .pipe(
              map(response => response.exists),  // Extraction du booléen 'exists' de la réponse
              catchError(() => of(false))  // En cas d'erreur, retourne 'false' par défaut
          );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  registerUser(rawValue: any) {
      return this.http.post<{ exists: boolean }>(environment.apiUrl+"/auth/store-user",rawValue);
  }
}
