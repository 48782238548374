import { Role } from "./role";

export class User {
  id: number;
  img: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Role;
  token: string;

  constructor(user) {
    this.img = user.img;
    this.username = user.username;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.role = user.role;
    this.token = user.token;
  }


}
