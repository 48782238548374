import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {UnsubscribeOnDestroyAdapter} from "../../../shared/UnsubscribeOnDestroyAdapter";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Administrateur} from "../../../admin/administrations/models/administrateur.model";
import { environment } from "src/environments/environment";
import {StructureService} from "../../parametre/structure.service";

@Injectable({
  providedIn: 'root'
})
export class AdministrateurService extends UnsubscribeOnDestroyAdapter {

  isTblLoading = true;
  dataChange: BehaviorSubject<Administrateur[]> = new BehaviorSubject<Administrateur[]>(
      []
  );

  // Temporarily stores data from dialogs
  dialogData: any;
  structureService: StructureService;

  constructor(private httpClient: HttpClient) {
    super();
    this.structureService = new StructureService(httpClient);

  }

  get data(): Administrateur[] {
    return this.dataChange.value;
  }

  /** CRUD METHODS */

  getAllAdministrateurs(structure_id): void {
    this.subs.sink = this.httpClient.get<any>(environment.apiUrl+"/admin/administrateurs?structure_id="+structure_id).subscribe(
        (response) => {
          this.isTblLoading = false;
          this.dataChange.next(response['data']);
        },
        (error: HttpErrorResponse) => {
          this.isTblLoading = false;
          console.log(error.name + " " + error.message);
        }
    );
  }

  getDialogData() {
    return this.dialogData;
  }

  getDashboard() {
        this.httpClient.get<any>(environment.apiUrl+"/admin/dashboard").subscribe(
            (data) => {
              //console.log(data);
            },
            (error: HttpErrorResponse) => {
              this.isTblLoading = false;
              console.log(error.name + " " + error.message);
            }
        );
  }

  storeAdministrateur(admindatas: any) {
      return this.httpClient.post(environment.apiUrl+"/admin/administrateurs",admindatas);
  }

  updateAdministrateur(admindatas: any) {
      return this.httpClient.put(environment.apiUrl+"/admin/administrateurs/"+admindatas.id,admindatas);
  }
  getHonoraires(honoraireDatas) {
      return this.httpClient.post(environment.apiUrl+"/admin/administrateurs/get-honoraires",honoraireDatas);
  }

  getAdministrateurs(autorisation=0,admin_id=null)
  {
      return this.httpClient.get<any>(environment.apiUrl+"/admin/administrateurs?autorisation="+autorisation+"&admin_id="+admin_id+"&structure_id="+this.structureService.currentStructureValue.id);
  }
}
