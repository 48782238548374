import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../core/service/auth.service";
import {Router} from "@angular/router";
import {StructureService} from "../../services/parametre/structure.service";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-home-sidebar',
  templateUrl: './home-sidebar.component.html',
  styleUrls: ['./home-sidebar.component.sass']
})
export class HomeSidebarComponent implements OnInit {
  userFullName: any;
  userType: any;
  userImg: string;
  listMaxHeight: string;
  listMaxWidth: string;
  structureInfos:any = {
    daara:null,
    prescolaire:null,
    primaire:null,
    college:null,
    lycee:null
  };

  constructor(
      private authService: AuthService,
      private router: Router,
      private structureService: StructureService,
  ) { }

  isActive(path: string): boolean {
    return this.router.isActive(path, false);
  }

  ngOnInit(): void {

    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;
      this.userFullName =
          this.authService.currentUserValue.firstName +
          " " +
          this.authService.currentUserValue.lastName;
      this.userImg = this.authService.currentUserValue.img;
      this.userType = userRole;
    }

    //Get Structure Info
    this.structureService.getStructures().subscribe(
        (reponse) => {
          this.structureInfos = reponse['data'];
        },
        (error : HttpErrorResponse) => {
          console.log(error);
        }
    );


  }

  mouseHover(e) {
    
  }
  mouseOut(e) {
    
  }

  logout(){
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }

  goSettings() {
    this.router.navigate(["/home/settings"]);
  }


  goAccueil() {
    this.router.navigate(["/home/accueil"]);
  }

  goLycee() {
    this.structureService.setCurrentStructure(this.structureInfos.lycee);
    this.router.navigate(["/admin/dashboard"]);
  }

  goCollege() {
    this.structureService.setCurrentStructure(this.structureInfos.college);
    this.router.navigate(["/admin/dashboard"]);
  }

  goPrimaire() {
    this.structureService.setCurrentStructure(this.structureInfos.primaire);
    this.router.navigate(["/admin/dashboard"]);
  }

  goPrescolaire() {
    this.structureService.setCurrentStructure(this.structureInfos.prescolaire);
    this.router.navigate(["/admin/dashboard"]);
  }

  goDaara() {
    this.structureService.setCurrentStructure(this.structureInfos.daara);
    this.router.navigate(["/admin/dashboard"]);
  }



  goEtablissement() {
    this.router.navigate(["/home/etablissements"]);
  }

  goAnneeScolaire() {
    this.router.navigate(["/home/annee-scolaires"]);
  }
}
